import Vue from 'vue'
import VueRouter from 'vue-router'
import $store from '@/store'

const Index = () => import('@/pages/index')
const Other = () => import('@/pages/other')
const Detail = () => import('@/pages/detail')
const Info = () => import('@/pages/info')
const Summary = () => import('@/pages/summary')
const Education = () => import('@/pages/education')
const Intention = () => import('@/pages/intention')
const Customer = () => import('@/pages/customer')
const Wo = () => import('@/pages/wo')
const Dyzbj = () => import('@/pages/dyzbj')



Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    },
    routes: [{
        path: '/', 
        component: Index, 
        meta: { 
            title: '云招聘', 
            keepAlive: false,
            isUseCache: false,
            isLogin: false,
        }
    },{
        path: '/other', 
        component: Other, 
        meta: { 
            title: '招聘会职位', 
            keepAlive: false,
            isUseCache: false,
            isLogin: false,
        }
    },{
        path: '/detail', 
        component: Detail, 
        meta: { 
            title: '岗位详情', 
            keepAlive: false,
            isUseCache: false,
            isLogin: false,
        }
    },{
        path: '/info', 
        component: Info, 
        meta: { 
            title: '基本信息', 
            keepAlive: false,
            isUseCache: false,
            isLogin: true,
        }
    },{
        path: '/summary', 
        component: Summary, 
        meta: { 
            title: '个人总结',
            keepAlive: false,
            isUseCache: false,
            isLogin: true,
        }
    },{
        path: '/education', 
        component: Education, 
        meta: { 
            title: '教育经历',
            keepAlive: false,
            isUseCache: false,
            isLogin: true,
        }
    },{
        path: '/intention', 
        component: Intention, 
        meta: { 
            title: '求职意向',
            keepAlive: false,
            isUseCache: false,
            isLogin: true,
        }
    },{
        path: '/customer', 
        component: Customer, 
        meta: { 
            title: '云招聘',
            keepAlive: false,
            isUseCache: false,
            isLogin: false,
        }
    },{
        path: '/dyzbj', 
        component: Dyzbj, 
        meta: { 
            title: '直播送岗',
            keepAlive: false,
            isUseCache: false,
            isLogin: false,
        }
    },{
        path: '/wo', 
        component: Wo, 
        meta: { 
            title: '关于我们',
            keepAlive: false,
            isUseCache: false,
            isLogin: false,
		}
    }],
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    if(to.meta.isLogin && !$store.state.user.user_info){
        $store.dispatch("user/getUserInfo");
    }
    next();
})

export default router